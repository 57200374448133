<template>
  <b-modal
    id="formation-info-modal"
    ref="formation-info-modal"
    :ok-disabled="valid"
    :disabled="valid"
    hide-header
    header-class="no-borders"
    size="md"
    ok-title="Continue"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    @ok="submitCompanyFormationInfo"
  >
    <b-container class="container-fluid max-width">
      <h3 class="mt-0">
        Company Formation:
      </h3>
      <br>
      <b-form-group label="Have you already formed your company?">
        <b-form-radio v-model="companyFormed" name="company-formed" :value="false">
          No, I would like to form a company
        </b-form-radio>
        <b-form-radio v-model="companyFormed" name="company-formed" :value="true">
          Yes, I already have a company
        </b-form-radio>
      </b-form-group>

      <b-form-group v-if="companyFormed" label="What is your Company's formation Date?">
        <b-form-datepicker
          id="formation-date"
          v-model="formationDate"
          :max="maxDate"
          :state="true"
          placeholder="Choose a formation date" class="mb-2"
          today-button
          reset-button
          close-button
        />
        <div class="row-or">
          <div>
            or
          </div>
        </div>
        <b-form-radio
          v-model="formationDate"
          :value="'unknown'"
        >
          I can't remember my formation date.
        </b-form-radio>
      </b-form-group>
    </b-container>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'FormationInfoModal',
  props: {
    company: Object,
  },
  data() {
    return {
      companyFormed: null,
      formationDate: null,
      maxDate: this.setMaxDate(),
    }
  },
  computed: {
    valid() {
      if (this.companyFormed !== null && this.formationDate !== null){
        return false
      } else if (this.companyFormed === false && this.formationDate === null){
        return false
      }
      return true
    },
  },
  methods: {
    ...mapActions('companies', [
      'setCurrentCompany',
      'updateCompanyConfig',
    ]),
    show() {
      this.$refs['formation-info-modal'].show()
    },
    setMaxDate() {
      const date = new Date()
      return new Date(date.getFullYear(), date.getMonth(), date.getDate())
    },
    async submitCompanyFormationInfo() {
      await this.updateCompanyStageline()
      this.$emit('formation-info-modal-complete', true)
      this.$refs['formation-info-modal'].hide()
    },

    async updateCompanyStageline() {
      // TODO Might there be a more feature-agnostic place to store something as critical as formationDate?
      // TODO: I believe this should update their domestic registration, not just be stored in a config
      const config = {
        formed_elsewhere: this.companyFormed,
        formation_date: this.formationDate,
      }
      await this.updateCompanyConfig({
        companyId: this.company.id,
        config: config,
      })

      // TODO We might not need this anymore since I set the above call to a store action which
      // refreshes the current company after update.
      await this.setCurrentCompany({ id: this.company.id, force: true })
    },
  },
}
</script>
<style scoped lang="scss">
  #formation-info-modal {
    label#formation-date__value_ {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    legend {
      font-size: 1.2em !important;
      font-weight: 700 !important;
    }

    .row-or {
      margin: 2em auto;
      width: 35%;
      height: 1px;
      background-color: $ct-ui-font-color-secondary;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      div {
        color: $ct-ui-font-color-secondary;
        padding: 1.25em;
        background: white;
        text-align: center;
      }
    }
  }
</style>
